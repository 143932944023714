import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Main } from './Component/Main'
import { AboutUs } from './Component/ExtraPages/AboutUs'
import { Cancellation } from './Component/ExtraPages/Cancellation'
import { Contact } from './Component/ExtraPages/contact'
import { Pricing } from './Component/ExtraPages/Pricing'
import { Terms } from './Component/ExtraPages/Terms'
import { Privacy } from './Component/ExtraPages/Privacy'

export const AllRoutes = () => {
  return (
    <>
        <Routes>
            <Route exact path='/' element={<Main></Main>}></Route>
            <Route exact path= '/AboutPage' element={<AboutUs/>}></Route>
    <Route exact path='/cancellation' element={<Cancellation/>}></Route>
    <Route exact path='/contact' element={<Contact/>}></Route>
    <Route exact path= '/Pricing' element={<Pricing/>}></Route>
    <Route exact path='/terms' element={<Terms/>}></Route>
    <Route exact path='/privacy' element={<Privacy/>}></Route>
        </Routes>
    </>
  )
}
