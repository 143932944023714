import React from 'react'
import { Link } from 'react-router-dom'

export const Terms = () => {
  return (
    <>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'10vh',fontWeight:'bold'}}>
    <h1>Terms and Conditions</h1>
  </div>
  <div>
 
    <ul>
      <li style={{fontWeight:'bold',fontSize:'20px'}}> Terms:</li>
      <p><span style={{fontWeight:'bold'}}>Intellectual property rights:</span> Teen Patti Prime / Teen Patti Prime do not infringe any copyright.If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Sites by completing the following Notice of Alleged Infringement and delivering it to Teen Patti Prime's registered address. Upon receipt of the Notice as described below, Teen Patti Prime will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged material from the Sites.
        <br></br>
        <br></br>
        <span style={{fontWeight:'bold'}}>Notice of Alleged Infringement (“Notice”)</span>
        <br></br>
        Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are covered by this Notice - you may provide a representative list of the copyrighted works that you claim have been infringed. Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the Site(s) where such material may be found. 
        <br></br>
        <br></br>
        Provide your mailing address, telephone number, and, if available, email address. Include both of the following statements in the body of the Notice:
         <br></br>
         <br></br>
         “I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).”
         <br></br>
         <br></br>
           Provide your full legal name and your electronic or physical signature.
          <br></br> 
          <br></br>    
         Deliver this Notice, with all items completed, to Teen Patti Prime's registered address.
           </p>

        <li style={{fontWeight:'bold',fontSize:'20px'}}>Disclaimer and Limitation of Liabilities:</li>
        <p>Teen Patti Prime <span style={{color:'red'}}>SHALL NOT BE LIABLE</span> for INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, even if Teen Patti Prime HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Teen Patti Prime SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: 
        <br></br>
        (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR 
        <br></br>
        (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF Teen Patti Prime HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Teen Patti Prime SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND Teen Patti Ultra’S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY SERVICE PROVIDERS PROVIDING SERVICES REQUESTED THROUGH THEM MAY OFFER SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL Teen Patti Ultra’S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED FIVE HUNDRED RUPEES (INR 500)
        <br></br>
        <br></br>
        THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
                            
       <br></br>
       <br></br>
       THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” Teen Patti Prime DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, Teen Patti Prime MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. Teen Patti Prime DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
       </p>
       <li style={{fontWeight:'bold',fontSize:'20px'}}>Third party services and content:</li>
       <p>The Services may be made available or accessed in connection with third party services and content (including advertising) that Teen Patti Prime/ Teen Patti Prime does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such third party services and content. Teen Patti Prime/ Teen Patti Prime does not endorse such third party services and content and in no event shall Teen Patti Prime/ Teen Patti Prime be responsible or liable for any products or services of such third party providers. Additionally, Apple Inc., Google, Inc. and/or their applicable international subsidiaries and affiliates will be third-party beneficiaries to this contract if you access the Services using Applications developed for Apple iOS, Android powered mobile devices, respectively. These third party beneficiaries are not parties to this contract and are not responsible for the provision or support of the Services in any manner. Your access to the Services using these devices is subject to terms set forth in the applicable third party beneficiary’s terms of service.</p>
    </ul>

  </div>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'6vh',fontSize:'19px'}}>
  2023-2024  <Link to='https://www.maxwayinfotech.com/'> Maxway Infotech</Link>
  </div>
  </>
  )
}
