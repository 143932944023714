import React from 'react'
import '../Component/Footer.css'
import { Link } from 'react-router-dom'
import secured from '../Image/100secured.webp'
import { FaInstagram } from "react-icons/fa6";
import { FiYoutube } from "react-icons/fi";
import { CiFacebook } from "react-icons/ci";
import { RiTelegramLine } from "react-icons/ri";
export const Footer = () => {
  return (
    <>
      <div className='MainCont'>
        <div style={{ height: '18vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div style={{ width: '30%' }}>
            <img src={secured} alt='' style={{ width: '100%', height: '12vh' }} />
          </div>
          <div style={{ width: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontSize: '20px', color: 'white' }}>
            <div><FaInstagram /></div>
            <div><FiYoutube /></div>
            <div><CiFacebook /></div>
            <div><RiTelegramLine /></div>
          </div>
        </div>
        <div style={{ height: '3vh', display: 'flex', justifyContent: 'center', fontSize: '14px', color: 'gold' }}>
          <p>TeenPattiPrime.online</p>
        </div>
        <div style={{ height: '3vh', display: 'flex', justifyContent: 'center', fontSize: '14px', color: 'gold' }}>
          <p>*This Game is Only meant for the 18+age group.</p>
        </div>
        <div style={{ height: '6vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div>
            <Link to='/AboutPage' style={{ color: 'white' }}>
              About Us
            </Link>
          </div>
          <div>
            <Link to='/contact' style={{ color: 'white' }}>
              Contact Us
            </Link>
          </div>
          <div>
            <Link to='/Pricing' style={{ color: 'white' }}>
              Pricing
            </Link>
          </div>
          <div>
            <Link to='/privacy' style={{ color: 'white' }}>
              Privacy Policy
            </Link>
          </div>
        </div>
        <div style={{ height: '5vh',  display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div>
            <Link to='/cancellation' style={{ color: 'white' }}>
            Cancellation and Refund Policy
            </Link>
          </div>
          </div>
          <div style={{ height: '4vh',display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>

          <div>
            <Link to='/terms' style={{ color: 'white' }}>
            Terms and Conditions
            </Link>
          </div>
          
       
          
        </div>

        <div style={{borderBottom:'1px solid #afafaf',height:'2vh',width:'100%'}}></div>
    <div  className='FooterCont'>
         <p>Copyright 2023 <span style={{fontWeight:'bold'}}>TeenPattiPrime.online </span>All rights reserved</p>
         </div>
      </div>
    </>
 
  )
}
