import React from 'react'
import banner from '../Image/logo5.webp'
import '../Component/Cards/cards.css'
import roullete from '../Image/roullete.webp'
import dragon from '../Image/dragon.webp'
import updown from '../Image/7updown.webp'
import joined from '../Image/joined_player_button.webp'
import joinbtn from '../Image/join_video.png'
import head from '../Image/Head.png'
export const Home = () => {
  return (
    <>

      <div>
            <img src={head} alt='' style={{height:'10vh',width:'100%'}}/>
        </div>
     <div style={{borderBottom:'1px solid #51ffef'}}>
        <img src={banner} alt='' style={{width:'100%',height:'auto'}}/>
    </div>
    <div className='wrapper' style={{background:'linear-gradient(180deg, #1a1550, #2a1438)',height:'220vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    
    <div style={{position:'relative',width:'90%',height:'20vh'}}>
          <img className='animate__animated animate__pulse animate__infinite animate__slow' src={joined} alt='' style={{width:'100%'}}/>
          <div style={{position:'absolute',top:'50%',left:'0%',width:'100%',display:'flex',justifyContent:'center',alignItems:'center',height:'6vh'}}>
          <div>
          <p style={{fontSize:'14px',fontWeight:'bold',color:'gold'}}>No. of Player Joined <span style={{color:'white'}}>24,050,040</span></p>
          </div>
              
          </div>
          </div>
    <div class="container-fostrap">
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12">
                        <div class="card animate__animated animate__bounceInLeft animate__slow">
                            <div style={{position:'relative'}}>
                            <a class="img-card">
                            <img src={roullete}/>
                          </a>
                          <div className='gameName'>
                                Roulette
                          </div>
                            </div>
                       
                            <div class="card-content">
                               
                                <p class="" style={{color:'white'}}>
                                  
A roulette game is a popular casino game of chance where players place bets on various outcomes, typically on a spinning wheel with numbered slots. 
                                </p>
                                <div class="card-read-more">
                                <a href="http://www.fostrap.com/2016/03/bootstrap-3-carousel-fade-effect.html" class="btn btn-link btn-block">
                                    Play Now
                                </a>
                            </div>
                            </div>
                          
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12">
                        <div class="card animate__animated  animate__bounceInRight animate__slow">
                        <div style={{position:'relative'}}>
                        <a class="img-card">
                            <img src={dragon} />
                          </a>
                          <div className='gameName2'>
                                Dragon VS Tiger
                          </div>
                        </div>
                           <div class="card-content">
                               
                                <p class="" style={{color:'white'}}>
                                Dragon Tiger is a fast-paced, simplified card game where players bet on whether the Dragon or the Tiger hand will receive the highest card.                                </p>
                                <div class="card-read-more">
                                <a href="https://codepen.io/wisnust10/full/ZWERZK/" class="btn btn-link btn-block">
                                    Play Now
                                </a>
                            </div>
                            </div>
                          
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12">
                        <div class="card animate__animated animate__bounceInLeft animate__slow">
                           <div style={{position:'relative'}}>
                           <a class="img-card">
                            <img src={updown}/>
                          </a>
                          <div className='gameName1' style={{position:'absolute',background:'linear-gradient(123deg, #ed0cff, #4eeaff)',height:'4vh',top:'20px',right:'0px',color:'white',width:'25%',fontWeight:'bold'}}>
                                7 Up Down
                          </div>
                           </div>
                            <div class="card-content">
                                <p class="" style={{color:'white'}}>
                  7 Up Down is a simple yet addictive dice game where players predict whether the next roll of the dice will result in a number higher or lower than 7.                                 </p>
                                <div class="card-read-more">
                                <a href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html" class="btn btn-link btn-block">
                                Play Now
                                </a>
                            </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style={{height:'10vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <img src={joinbtn} alt='' style={{width:'100%',height:'7vh'}}/>
    </div>
 </div>
    
    
    </>
   
  )
}
