import React from 'react'
import { Nav } from './Nav'
import { Home } from './Home'
import { Footer } from './Footer'
import { Downloadbtn } from './Downloadbtn/Downloadbtn'

export const Main = () => {
  return (
  <>
 <div style={{backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',height:'354vh'}}>
    
    <div style={{height:'300vh',
    width:'500px'}}>
    
  {/* <div className='fixed-top'>
  <Nav/>
  </div>  */}
   <div>
   <Home/>
   </div>
   <div>
    <Footer/>
   </div>
   <div style={{position:'fixed',bottom:'15px'}}>
    <div>
    <Downloadbtn/>
    </div>
 
  </div>
  </div> 

  </div>
 

 
  </>
  )
}
